import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { login_template } from "../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/login_template";

declare var page: any;
declare var ko: any;

export class login extends vnModule_knockoutJS{

    protected loginTemplate: login_template;
    protected requestedUrl:  string;

    protected getModuleName(){
        return "login";
    }

    buildContent() : Array<Element>{

        this.loginTemplate = new login_template("loginForm", this.viewModel, this);
        return [this.loginTemplate.build()];
    }

    // template event
    public redirectToLogin(){
        page.redirect('/');
    }

    // template event
    protected onSubmitLoginForm(){

        this.requestedUrl = window.location.pathname;
        let loginReq_url: string = '/api/access/login/';
        let loginReq_Params: Array<Array<string>> = [];
        loginReq_Params.push( ["data", ko.toJSON(this.viewModel["loginForm"])] );
        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(loginReq_url,loginReq_Params, this);
        XHRHdl.onReadyStateFunction = (function(req, obj){

            return function(){

                if (req.request.readyState == 4) {

                    let responseParsed = JSON.parse(req.request.response);
                    let actualModule = (<login>obj);

                    if(req.request.status == 200){

                        // 2fa
                        if (responseParsed.twoFaNeeded && responseParsed.user != null) {
                            actualModule.loginTemplate.enableTwoFactorVerification(true, responseParsed.user.id, responseParsed.user.dateTwoFactorExpires);
                            return; 
                        }

                        if(actualModule.requestedUrl != "/") {
                            page.redirect(actualModule.requestedUrl);
                        } else {
                            page.redirect('/home');
                        }
                    }
                    else{
                        actualModule.onUpdateError(req.request.status, responseParsed)
                    }
                }
            }
        });
        XHRHdl.execute();
    }

    // template event
    public retrievePassword(){

        let loginReq_url: string = '/api/access/retrievepassword/';
        let loginReq_Params: Array<Array<string>> = [];
        loginReq_Params.push( ["data", ko.toJSON(this.viewModel["loginForm"])] );
        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(loginReq_url,loginReq_Params, this);
        XHRHdl.onReadyStateFunction = (function(req, obj){

            return function(){

                if (req.request.readyState == 4){

                    let responseParsed = JSON.parse(req.request.response);
                    let actualModule = (<login>obj);

                    if(req.request.status == 200){

                        document.querySelector("#retrieve-password").classList.add("is-hidden");
                        actualModule.loginTemplate.getTemplateViewModel().sentMessage("block");
                        actualModule.loginTemplate.getTemplateViewModel().retrieveInfo("Please verify your inbox, we have sent you an email with instructions on how to reset your password.");
                        actualModule.loginTemplate.clearValidationsMessages();
                    }
                    else{

                        actualModule.onUpdateError(req.request.status, responseParsed);
                    }
                }
            }
        });
        XHRHdl.execute();
    }

    protected onUpdateError(requestStatus: any, responseParsed) : void{

        this.loginTemplate.setError(responseParsed);
    }

    public async onSubmitOtpForm(): Promise<void> {
        try {
            let fullOtpCode = this.viewModel.loginForm.getFullOtpCode();
            const otpReqPayload = {
                data: JSON.stringify({
                    userId: this.viewModel.loginForm.userId(),
                    otpCode: fullOtpCode
                })
            };
    
            const response = await fetch("/api/access/verify-otp/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(otpReqPayload),
            });
    
            const responseParsed = await response.json();
    
            if (response.ok) {
                if (responseParsed.success) {
                    this.loginTemplate.getTemplateViewModel().otpSuccessMessage("Two-Factor Authentication Success.");
                    this.loginTemplate.getTemplateViewModel().otpFailureMessage("");
                    
                    let { redirectUrl, queryString } = this.getRedirectUrlWithQuery();
                    page.redirect(redirectUrl + queryString);
                } else {
                    this.loginTemplate.getTemplateViewModel().otpFailureMessage(responseParsed.message || "Two-Factor Authentication has Failed.");
                    this.loginTemplate.getTemplateViewModel().otpSuccessMessage("");
    
                    if (responseParsed.disableVerify) {
                        this.loginTemplate.disableVerify(true);
                    }
                }
            } else {
                this.loginTemplate.getTemplateViewModel().otpFailureMessage(responseParsed.message || "Two-Factor Authentication has Failed.");
                this.loginTemplate.getTemplateViewModel().otpSuccessMessage("");
    
                if (responseParsed.disableVerify) {
                    this.loginTemplate.disableVerify(true);
                }
            }
        } catch (error) {
            this.loginTemplate.getTemplateViewModel().otpFailureMessage("An unexpected error occurred.");
            this.loginTemplate.getTemplateViewModel().otpSuccessMessage("");
        }
    }

    public async onResendOtpCode(): Promise<void> {
        try {
            const resendReqPayload = {
                data: JSON.stringify({
                    userId: this.viewModel.loginForm.userId(),
                    currentHost: this.viewModel.loginForm.currentHost()
                })
            };
    
            const response = await fetch("/api/access/resend-otp/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(resendReqPayload),
            });
    
            const responseParsed = await response.json();
    
            if (response.ok && responseParsed.otpExpiryDate) {
                this.loginTemplate.getTemplateViewModel().otpSuccessMessage(responseParsed.message);
                this.loginTemplate.getTemplateViewModel().otpFailureMessage("");
                this.loginTemplate.setIs2FaVerifyDisabled(false);
                this.loginTemplate.setDateTwoFactorExpires(responseParsed.otpExpiryDate);
            } else if (response.status === 429) {
                if (responseParsed.message) {
                    this.loginTemplate.getTemplateViewModel().otpFailureMessage(responseParsed.message || "Resending Two-Factor Authentication has Failed.");
                    this.loginTemplate.getTemplateViewModel().otpSuccessMessage("");
                }
                this.loginTemplate.setIsResendDisabled(true);
                setTimeout(() => {
                    this.loginTemplate.setIsResendDisabled(false);
                }, 60000); 
            } else if (response.status === 403) {
                if (responseParsed.message) {
                    this.loginTemplate.getTemplateViewModel().otpFailureMessage(responseParsed.message || "Resending Two-Factor Authentication has Failed.");
                    this.loginTemplate.getTemplateViewModel().otpSuccessMessage("");
                }
                if (responseParsed.disableResend) {
                    this.loginTemplate.setIsResendDisabled(true);
                }
                if (responseParsed.disableCountDown) {
                    this.loginTemplate.setIsCountdownDisabled(true);
                }
            } else {
                this.loginTemplate.getTemplateViewModel().otpFailureMessage(responseParsed.message || "Resending Two-Factor Authentication has Failed.");
                this.loginTemplate.getTemplateViewModel().otpSuccessMessage("");

                if (responseParsed.disableResend) {
                    this.loginTemplate.setIsResendDisabled(true);
                }
                if (responseParsed.disableCountDown) {
                    this.loginTemplate.setIsCountdownDisabled(true);
                }
            }
        } catch (error) {
            this.loginTemplate.getTemplateViewModel().otpFailureMessage("An unexpected error occurred.");
            this.loginTemplate.getTemplateViewModel().otpSuccessMessage("");
        }
    }
    
    private getRedirectUrlWithQuery(): { redirectUrl: string; queryString: string } {
        let defaultUrl = "/home";
        let queryString = window.location.search || "";
    
        for (let i = this.vnAppZone.app.routeHistory.length - 1; i >= 0; i--) {
            let route = this.vnAppZone.app.routeHistory[i];
    
            if (route !== "/" && route !== "/login" && !route.includes("/reset/token/")) {
                return { redirectUrl: route, queryString };
            }
        }
    
        return { redirectUrl: defaultUrl, queryString };
    }
}
