import {vnTemplate} from "./vnTemplate";
import {validationsList_template} from "./validationsList_template";
import {pagination_template} from "./pagination_template";
import {filterSimple_template} from "./filterSimple_template";

declare var ko: any;

export class login_template extends vnTemplate{

    protected validationsList: validationsList_template;

    static readonly ERROR_TYPE_VALIDATION = "validation";

    protected getTemplateHtml(): string{

        return `

            ` + this.validationsList.getHtmlBuild() + `

                <div class="w-form center login-form" id="login-form" data-bind="visible: !is2FaEnabled()">
                    <div class="form-header">
                        <h3 class="form-title icon i-login">Log In</h3>
                        <button class="close-button icon i-close-2" type="button"></button>
                    </div>
                    <form class="form-body">
                        <div class="form-group">
                            <label class="form-label">Username or Email Address</label>
                            <input data-bind="value: u, uniqueName: true, event: { keyup: onKeyUpLoginAction}" type="text" class="input-text"  />
                        </div>
                        <div class="form-group">
                            <label class="form-label">Password</label>
                            <input data-bind="value: p, uniqueName: true, event: { keyup: onKeyUpLoginAction}" type="password" class="input-text" />
                            <button class="btn-text" type="button" data-bind="event: { click: onClickForgotPasswordAction}">Forgot your password</button>
                        </div>
                        <div data-bind="visible:isEmakiHost" class="form-group">
                            <p class="form-text" style="opacity: .5">
                                <a href="/about" target="_blank">About Emaki</a>
                                <span style="margin: 0 2px">|</span>
                                <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                                <span style="margin: 0 2px">|</span>
                                <a href="/terms-of-use" target="_blank">Terms of Use</a>
                            </p>
                        </div>
                    </form>
                    <div class="form-footer">
                        <button data-bind="event: { click: onSubmitLoginForm}" type="button" class="btn highlight">log in</button>
                        <button type="button" class="btn">Cancel</button>
                    </div>
                </div>

                <div class="w-form center two-fa-form" id="otp-form" style="max-width: 400px" data-bind="visible: is2FaEnabled">
                    <div class="form-header">
                        <h3 class="form-title icon i-login">Two-Factor Authentication</h3>
                        <button data-bind="event: { click: onClickCloseRetrieveAction}" class="close-button icon i-close-2" type="button"></button>
                    </div>
                    <form class="form-body">
                        <div class="form-group">
                            <p class="form-text" style="text-align: center">Your <span data-bind="text: appName"></span> account is secured with two-factor authentication. A code has been sent to your email address.</p>  
                        </div>
                        <div class="form-group" style="text-align: center">
                            <label style="margin-bottom: 1.5rem;" class="form-label">Enter 2FA Code</label>
                            <div class="form-group">
                                <div class="two-fa">
                                    <input type="text" maxlength="1" placeholder="_" name="otp-1"
                                        data-bind="value: otpCode1, uniqueName: true, event: { input: onOtpInput, keyup: onOtpKeyUp, paste: onOtpPaste }">
                                    <input type="text" maxlength="1" placeholder="_" name="otp-2"
                                        data-bind="value: otpCode2, uniqueName: true, event: { input: onOtpInput, keyup: onOtpKeyUp }">
                                    <input type="text" maxlength="1" placeholder="_" name="otp-3"
                                        data-bind="value: otpCode3, uniqueName: true, event: { input: onOtpInput, keyup: onOtpKeyUp }">
                                    <input type="text" maxlength="1" placeholder="_" name="otp-4"
                                        data-bind="value: otpCode4, uniqueName: true, event: { input: onOtpInput, keyup: onOtpKeyUp }">
                                    <input type="text" maxlength="1" placeholder="_" name="otp-5"
                                        data-bind="value: otpCode5, uniqueName: true, event: { input: onOtpInput, keyup: onOtpKeyUp }">
                                    <input type="text" maxlength="1" placeholder="_" name="otp-6"
                                        data-bind="value: otpCode6, uniqueName: true, event: { input: onOtpInput, keyup: onOtpKeyUp }">
                                </div>
                            </div>
                        </div>
                        <div class="form-group" style="height: 3rem">
                            <p style="text-align: center;margin-bottom: 0rem" class="form-text" data-bind="visible: !isCountdownDisabled()">
                                Code expires in <span data-bind="text: countdownTimer"></span>.
                            </p>
                            <p class="form-text success" style="text-align: center" data-bind="visible: otpSuccessMessage, text: otpSuccessMessage"></p>
                            <p class="form-text fail" style="text-align: center" data-bind="visible: otpFailureMessage, text: otpFailureMessage"></p>                            
                        </div>            
                    </form>
                    <div class="form-footer">
                        <button type="button" class="btn highlight" data-bind="event: { click: onSubmitOtpForm }, enable: !is2FaVerifyDisabled()">Verify</button>
                        <button type="button" class="btn" data-bind="event: { click: onResendOtpCode }, enable: !isResendDisabled()">Resend Code</button>
                    </div>
                </div>

                <div class="w-form over center is-hidden" id="retrieve-password">
                    <div class="form-header">
                        <h3 class="form-title icon i-user">Please Enter your Username or Email Below</h3>
                        <button data-bind="event: { click: onClickCloseRetrieveAction}" type="button" class="close-button icon i-close-2"></button>
                    </div>
                    <form class="form-body" onsubmit="return false;">
                        <div>
                            <div class="form-group">
                                <input data-bind="value: userInput" type="hidden" />
                                <div class="form-check-inline width-auto">
                                    <input data-bind="event: { click: onClickRadioPasswordAction}" type="radio" name="radioPass" id="radio_email" value="1" checked>
                                    <label class="form-label" for="radio_email">Email</label>
                                </div>
                                <div class="form-check-inline width-auto">
                                    <input data-bind="event: { click: onClickRadioPasswordAction}" type="radio" name="radioPass" id="radio_name" value="0" >
                                    <label class="form-label" for="radio_name">User Name</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <input data-bind="value: retrieveInput, event: { keyup: onKeyUpSentLinkAction}" id="retrieveInput" type="text" class="input-text"/>
                            </div>
                        </div>
                    </form>
                    <div class="form-footer">
                        <button class="btn" type="button" data-bind="event: { click: onClickRetrievePasswordAction}">Send Me Reset Link</button>
                    </div>
                </div>

                <div class="w-form center over is-hidden" data-bind="style: {display: sentMessage}">
                    <div class="form-header">
                        <h3 class="form-title icon i-user">Password update confirmation</h3>
                        <button data-bind="event: { click: onClickCloseRetrieveAction}" type="button" class="close-button icon i-close-2"></button>
                    </div>
                    <form class="form-body">
                        <div class="form-group">
                             <span data-bind="text: retrieveInfo" class="sub-title"></span>
                        </div>
                    </form>
                    <div class="form-footer">
                        <button  class="btn" type="button" data-bind="event: { click: onClickCloseRetrieveAction}">Go back to Log In</button>
                    </div>
                </div>
            `;
    }

    protected initPartial(): void {
        this.validationsList = new validationsList_template("validationList", this.getTemplateViewModel(), this.caller);
    
        const currentHost = this.getTemplateViewModel().currentHost();
        if (currentHost.includes('emakicms.com')) {
            this.getTemplateViewModel().appName("Emaki");
        } else if (currentHost.includes('adsninja.ca')) {
            this.getTemplateViewModel().appName("Ads Ninja");
        } else if (currentHost.includes('pubinsights.com')) {
            this.getTemplateViewModel().appName("Pub Insights");
        }
    }
    

    protected getTemplateName(): string{
        return "login-form-template";
    }

    protected getMainElementClasses(): Array<string>{
        return ["form-container"];
    }

    protected buildViewModel(): any {
        let huit_neuf_this = this;
    
        let viewModel = {
            u: "",
            p: "",
            retrieveInput: ko.observable(""),
            retrieveInfo: ko.observable(""),
            userInput: ko.observable("1"),
            sentMessage: ko.observable("none"),
            currentHost: ko.observable(window.location.host),
            isEmakiHost: ko.pureComputed(function () {
                const hostPattern = /^(emakicms\.com(\.[a-zA-Z0-9_-]+)?|ads\.emakicms\.com|qa\d+\.emakicms\.com|mig\d+\.emakicms\.com)$/;
                return hostPattern.test(huit_neuf_this.buildViewModel().currentHost());
            }),
            appName: ko.observable("Valnet Inc"),
            is2FaEnabled: ko.observable(false),
            userId: ko.observable(""),
            dateTwoFactorExpires: ko.observable(""), // Expected format: "YYYY-MM-DD HH:MM:SS"
            otpCode1: ko.observable(""),
            otpCode2: ko.observable(""),
            otpCode3: ko.observable(""),
            otpCode4: ko.observable(""),
            otpCode5: ko.observable(""),
            otpCode6: ko.observable(""),
            countdownTimer: ko.observable("00:00"), // Default countdown timer
            is2FaVerifyDisabled: ko.observable(false),
            countdownInterval: null,
            isResendDisabled: ko.observable(false),
            isCountdownDisabled: ko.observable(false),
            otpSuccessMessage: ko.observable(""),
            otpFailureMessage: ko.observable(""),
            onKeyUpLoginAction: this.onKeyUpLoginAction,
            onKeyUpSentLinkAction: function (data, event) {
                huit_neuf_this.onKeyUpSentLinkAction(data, event);
            },
            onClickForgotPasswordAction: this.onClickForgotPasswordAction,
            onClickRetrievePasswordAction: function (data, event) {
                huit_neuf_this.onClickRetrievePasswordAction();
            },
            onClickRadioPasswordAction: this.onClickRadioPasswordAction,
            onClickCloseRetrieveAction: function (data, event) {
                huit_neuf_this.onRedirectToLogin();
            },
            onSubmitLoginForm: function (data, event) {
                huit_neuf_this.caller.onSubmitLoginForm();
            },
            onSubmitOtpForm: function (data, event) {
                huit_neuf_this.caller.onSubmitOtpForm();
            },
            onResendOtpCode: function (data, event) {
                huit_neuf_this.caller.onResendOtpCode();
            },
            startCountdown(): void {
                if (!this.dateTwoFactorExpires() || this.dateTwoFactorExpires() === "") {
                    this.countdownTimer("Expired");
                    return;
                }
            
                let expirationTimeET = new Date(this.dateTwoFactorExpires()); 

                if (this.countdownInterval) {
                    clearInterval(this.countdownInterval);
                    this.countdownInterval = null;
                }
            
                this.countdownInterval = setInterval(() => {
                    let now = new Date(new Date().toLocaleString("en-US", { timeZone: "America/New_York" }));
                    let timeDiff: number = expirationTimeET.getTime() - now.getTime(); 
            
                    if (timeDiff <= 0) {
                        clearInterval(this.countdownInterval);
                        this.countdownTimer("Expired");
                        return;
                    }
            
                    let minutes: number = Math.floor(timeDiff / 60000); 
                    let seconds: number = Math.floor((timeDiff % 60000) / 1000); 
                    let formattedSeconds: string = seconds < 10 ? "0" + seconds.toString() : seconds.toString();
            
                    this.countdownTimer(`${minutes}:${formattedSeconds}`); 
                }, 1000);
            },
            onOtpPaste: function (data, event) {
                const pastedData = event.clipboardData.getData('text') || "";
                if (pastedData.length === 6 && /^\d+$/.test(pastedData)) {
                    const inputs = event.target.parentElement.querySelectorAll('input');
                    pastedData.split('').forEach((char, index) => {
                        if (inputs[index]) {
                            inputs[index].value = char;
                            data[`otpCode${index + 1}`](char);
                        }
                    });
            
                    setTimeout(() => {
                        let otpCode = data.getFullOtpCode();
                        if (otpCode.length === 6 && /^\d+$/.test(otpCode)) {
                            data.onSubmitOtpForm();
                        }
                    }, 0);
            
                    event.preventDefault(); 
                }
            },                   
            onOtpInput: function (data, event) {
                const input = event.target;
                const key = event.inputType || event.key;
            
                if (key === "deleteContentBackward" || key === "Backspace") {
                    if (input.value.length === 0) {
                        let prevInput = input.previousElementSibling;
                        if (prevInput && prevInput.tagName === "INPUT") {
                            prevInput.focus();
                        }
                    }
                } else if (input.value.length === 1) {
                    let nextInput = input.nextElementSibling;
                    if (nextInput && nextInput.tagName === "INPUT") {
                        nextInput.focus();
                    }
                }
            },
            onOtpKeyUp: function (data, event) {
                const fullOtpCode = data.getFullOtpCode();
                if (event.key === "Enter" && fullOtpCode.length === 6 && !data.is2FaVerifyDisabled()) {
                    data.onSubmitOtpForm();
                }
            },                                
            getFullOtpCode: function () {
                return `${viewModel.otpCode1()}${viewModel.otpCode2()}${viewModel.otpCode3()}${viewModel.otpCode4()}${viewModel.otpCode5()}${viewModel.otpCode6()}`;
            }        
        };
        viewModel.is2FaEnabled.subscribe(function (newValue) {
            if (newValue) {
                viewModel.startCountdown();
            }
        });
        viewModel.dateTwoFactorExpires.subscribe(function (newValue) {
            if (newValue) {
                viewModel.startCountdown();
            }
        });
        return viewModel;
    }    

    public onKeyUpLoginAction(data, event) {

        event.preventDefault();
        if (event.keyCode == 13) {
            data.onSubmitLoginForm();
        }
    }

    public onKeyUpSentLinkAction(data, event) {

        event.preventDefault();
        if (event.keyCode == 13) {
            this.caller.retrievePassword();
        }
        return false;
    }

    protected onClickForgotPasswordAction(data,event){
        event.preventDefault();
        let zone1 = document.querySelector("#retrieve-password");
        zone1.classList.remove("is-hidden");

        let zone2 = document.querySelector("#login-form");
        zone2.classList.add("is-hidden");

    }

    protected onClickRetrievePasswordAction(){
        this.caller.retrievePassword();
    }

    protected onClickRadioPasswordAction(data,event){
        if(event.target.getAttribute("id") == "radio_email"){
            data.userInput("1");
        }else{
            data.userInput("0");
        }
        data.retrieveInput("");

        return true;
    }

    protected onRedirectToLogin(){
        this.caller.redirectToLogin();
    }

    public setError(error): void{

        this.validationsList.setMessageUsingDefaultFormat(error);
        window.scrollTo(0, 0);
    }

    public clearValidationsMessages(){

        this.validationsList.clearMessages();
    }

    public enableTwoFactorVerification(is2FaNeeded, userId, dateTwoFactorExpires) {
        this.getTemplateViewModel().userId(userId);
        this.getTemplateViewModel().dateTwoFactorExpires(dateTwoFactorExpires);
        this.getTemplateViewModel().is2FaEnabled(is2FaNeeded);
    }

    public onSubmitOtpForm(){
        this.caller.onSubmitOtpForm();
    }

    public onResendOtpCode(){
        this.caller.onResendOtpCode();
    }

    public disableVerify(isDisabled){
        if (this.getTemplateViewModel().countdownInterval) {
            clearInterval(this.getTemplateViewModel().countdownInterval);
            this.getTemplateViewModel().countdownInterval = null; 
        }
        this.getTemplateViewModel().dateTwoFactorExpires("");
        this.getTemplateViewModel().is2FaVerifyDisabled(isDisabled);
        this.getTemplateViewModel().countdownTimer("Expired");
    }

    public setIsResendDisabled(isResendDisabled){
        this.getTemplateViewModel().isResendDisabled(isResendDisabled);
    }

    public setIs2FaVerifyDisabled(is2FaVerifyDisabled){
        this.getTemplateViewModel().is2FaVerifyDisabled(is2FaVerifyDisabled);
    }

    public setDateTwoFactorExpires(dateTwoFactorExpires){
        this.getTemplateViewModel().dateTwoFactorExpires(dateTwoFactorExpires);
    }

    public setIsCountdownDisabled(isCountDownDisabled){
        this.getTemplateViewModel().isCountdownDisabled(isCountDownDisabled);
    }
}
