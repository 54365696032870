import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import {vnApp_ads_ninja} from "../vnApp_ads_ninja";
import {infoButton_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/infoButton_template";

declare var ko: any;

export class usersEdit_template extends vnTemplate{

    protected twoFactorAuthenticationTooltipContent;
    protected twoFactorAuthenticationTooltip: infoButton_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <input id="user-id" data-bind="value: idUser" type="hidden">
                <!-- ko ifnot: $data.idUser() -->
                <h3 class="form-title icon i-user-add">Add User</h3>
                <!-- /ko -->

                <!-- ko if: $data.idUser() -->
                <h3 class="form-title icon i-user">edit User</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body">
                <div class="form-body">
                    <strong class="form-sub-title">personal info</strong>
                    <div class="form-group">
                        <legend class="form-legend">active</legend>
                        <div class="form-check-inline width-auto">
                            <input type="radio" name="isActive" id="active_yes" value="1" data-bind="checked: isActive, disable: disableActive" />
                            <label class="form-label" for="active_yes">Yes</label>
                        </div>
                        <div class="form-check-inline width-auto">
                            <input type="radio" name="isActive" id="active_no" value="0" data-bind="checked: isActive, disable: disableActive" />
                            <label class="form-label" for="active_no">No</label>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <label for="firstName" class="form-label">First Name</label>
                        <input id="firstName" data-bind="value: firstName" type="text" class="input-text">
                     </div>
                    <div class="form-group row">
                        <label for="lastName" class="form-label">Last Name</label>
                        <input id="lastName" data-bind="value: lastName" type="text" class="input-text">
                     </div>
                    <div class="form-group row">
                        <label for="loginName" class="form-label">User Name</label>
                        <input id="loginName" data-bind="value: loginName" type="text" class="input-text">
                    </div>
                    <div class="form-group row">
                        <label for="email" class="form-label">Email</label>
                        <input id="email" data-bind="value: email" type="email" class="input-text">
                    </div>
                
                    <!-- ko if: $data.idUser() -->
                     <div class="form-group row">
                        <input id="change-password" type="checkbox" data-bind="checked: isChangingPassword">
                        <label for="change-password" class="form-label icon i-check">Change Password?</label>
                    </div>
                    <!-- /ko -->

                    <div class="form-group-password" id="form-group-password", data-bind="css: { 'is-open': !$data.idUser()}">
                        <div class="form-group">
                            <label class="form-label" for="password">Password</label>
                            <input id="password" data-bind="value: password" type="password" class="input-pass">
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="passwordConfirmation">New Password Confirmation</label>
                            <input id="passwordConfirmation" data-bind="value: passwordConfirmation" type="password" class="input-pass">
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="oldPassword">Password of Logged User
                                <button class="w-tooltips icon i-tooltips" type="button">
                                    <span class="tooltips">Enter your OLD password here,<br>INCASE you are changing<br>to a new password</span>
                                </button>
                                
                            </label>
                            <input id="oldPassword" data-bind="value: oldPassword" type="password" class="input-pass">
                        </div>
                      
                    </div>

                    <div class="form-group">
                        <label for="2FA-select" class="form-label width-tooltip">
                            Two-Factor Authentication
                            <div class="w-tooltips-adv pos-top short">
                                ${ this.twoFactorAuthenticationTooltip.getHtmlBuild() }
                            </div>
                        </label>
                        <select id="2FA-select" name="2FA" class="form-control" data-bind="value: twoFactorMethod">
                            <option value="disabled" data-bind="attr: { disabled: !hasUpdateAccessToRolesAdmin() }">Disabled</option>
                            <option value="email">Enabled via Email</option>
                        </select>
                    </div>

                    
                      <div class="form-group">
                        <label class="form-label label-oneline inputfile-label" for="profile-pic">Profile Picture</label>
                        <div id="dropzone">
                            <div id="my-drop-zone" class="dropzone needsclick dz-clickable"></div>
                                <input id="profile-pic" data-bind="value: pictureUrl" type="hidden">
                            </div>
                    </div>
                    
                </div>
                <div class="form-body">
                    <strong class="form-sub-title">personal info</strong>
                    <div class="form-group">
                        <legend class="form-legend">Roles</legend>
                        <div data-bind="foreach: roles" >
                            <div class="form-check-inline">
                                <input data-bind="value: id, checked: $parent.selectedRoles, attr:{id:id}, disable: $parent.isRoleDisabled(id)" type="checkbox">
                                <label data-bind="text: name, attr:{for:id}" class="form-label icon i-check">Administrator</label>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div data-bind="visible: !hasAdminRole()" class="form-group">
                                 
                        <legend class="form-legend">Properties</legend>
                        <div data-bind="foreach: properties">
                            <div class="form-check-inline">
                                <input data-bind="value: id, checked: $parent.selectedProperties, attr:{id: 'property_' + id}, disable: $parent.isPropertyDisabled(id)" type="checkbox">
                                <label class="form-label icon i-check" data-bind="text: name, attr:{for: 'property_' + id}"></label>
                            </div>
                        </div>
                        <div class="margin-top" data-bind="visible: !disableSelectButtons()">
                            <button class="btn" type="button" id="select_all" data-bind="event: { click: onSelectAllAction}">Select All</button>
                            <button class="btn" type="button" id="deselect_all" data-bind="event: { click: onDeselectAllAction}">Deselect All</button>
                        </div>
                        
                    </div>
                    
                    <div data-bind="visible: hasAdminRole()" class="form-group">
                        <p class="form-text">
                            Administrator is granted access to all of the Projects.
                        </p>
                    </div>
                    
                  

                </div>
            </div>
            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>

            `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

        this.twoFactorAuthenticationTooltipContent = `
        <p style="font-size: 12px; white-space: normal">
        If 2FA is enabled, the additional configured authentication method will be prompted if the user’s device, browser, user agent, location, or connection are different from their previous login attempt or if a login with 2FA has not occurred in the last 14 days.
        </p>
        `;

        this.twoFactorAuthenticationTooltip = new infoButton_template('twoFactorAuthenticationTooltip', this.getTemplateViewModel(), this.caller)
        this.twoFactorAuthenticationTooltip.setTitle('Two-Factor Authentication');
        this.twoFactorAuthenticationTooltip.setContent(this.twoFactorAuthenticationTooltipContent);
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "usersEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "w-form-large", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;
        let viewModel      = {
            idUser: ko.observable(''),
            firstName: ko.observable(''),
            lastName: ko.observable(''),
            loginName: ko.observable(''),
            email: ko.observable(''),
            pictureUrl: ko.observable(null),
            password: ko.observable(''),
            roles: ko.observableArray([]),
            properties: ko.observableArray([]),
            selectedRoles: ko.observableArray([]),
            selectedProperties: ko.observableArray([]),
            passwordConfirmation: ko.observable(''),
            oldPassword: ko.observable(''),
            isActive: ko.observable("1"),
            disabledRoles: ko.observableArray([]),
            disabledProperties: ko.observableArray([]),
            disableActive: ko.observable(true),
            isChangingPassword: ko.observable(false),
            hasAdminRole: ko.observable(false),
            disableSelectButtons: ko.observable(false),
            locker: true,
            twoFactorMethod: ko.observable('email'),
            hasUpdateAccessToRolesAdmin: ko.observable(false),
            isRoleDisabled: function(data){ return ko.computed(function(){ return huit_neuf_this.isRoleDisabled(data); }, this)},
            isPropertyDisabled: function(data){ return ko.computed(function(){ return huit_neuf_this.isPropertyDisabled(data); }, this)},
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
            onSelectAllAction: function(){ huit_neuf_this.onSelectAllAction(this.properties(), this.disabledProperties()); },
            onDeselectAllAction: function(){ huit_neuf_this.onDeselectAllAction(this.properties()); },
        };

        viewModel.selectedRoles.subscribe(function(checked){

            huit_neuf_this.validateProperties();
        }, viewModel);
        viewModel.isChangingPassword.subscribe(function(checked){

            huit_neuf_this.displayChangePassword(checked);
        }, viewModel);

        return viewModel;
    }

    /**
     * Validates properties.
     */
    protected validateProperties(): void{

        let checked = this.getTemplateViewModel().selectedRoles();

        if((checked.indexOf(1) >= 0)){

            this.getTemplateViewModel().hasAdminRole(true);
            this.setSelectedProperties([]);
        }
        else{

            this.getTemplateViewModel().hasAdminRole(false);
        }
    }

    /**
     * Handles deselecting all the properties.
     *
     * @param allProperties
     */
    protected onDeselectAllAction(allProperties): void{

        this.getTemplateViewModel().selectedProperties([]);
    }

    /**
     * Handles selecting all properties.
     *
     * @param allProperties
     * @param disabledProperties
     */
    protected onSelectAllAction(allProperties, disabledProperties): void{

        let properties = [];

        for(let b of allProperties){

            if (disabledProperties.indexOf(b.id) < 0){

                properties.push(b.id);
            }
        }

        this.getTemplateViewModel().selectedProperties(properties);
    }

    /**
     * Sets selected roles.
     *
     * @param {Array<{id: number}>} selectedRoles
     */
    public setSelectedRoles(selectedRoles: Array<{id: number}>): void{

        let roles = [];

        for(let r of selectedRoles){
            roles.push(r.id);
        }

        this.getTemplateViewModel().selectedRoles(roles);
        this.validateProperties();
    }

    /**
     * Sets selected properties.
     *
     * @param {Array<{id: number}>} selectedProperties
     */
    public setSelectedProperties(selectedProperties: Array<{id: number}>): void{

        let properties = [];

        for(let c of selectedProperties){

            properties.push(c.id);
        }

        this.getTemplateViewModel().selectedProperties(properties);
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.saveEditUser();
    }

    /**
     * Determines if a role checkbox is disabled.
     *
     * @param {number} data
     * @return boolean
     */
    protected isRoleDisabled(data: number): boolean{

        let disabled: Array<any> = this.getTemplateViewModel().disabledRoles();

        console.log(disabled);

        for(let r of disabled){

            if(data == r || (r.hasOwnProperty('id') && data == r.id)){

                return true;
            }
        }

        return false;
    }

    /**
     * Determines if a property checkbox is disabled.
     *
     * @param {number} data
     * @return {boolean}
     */
    protected isPropertyDisabled(data: number): boolean{

        let disabled: Array<any> = this.getTemplateViewModel().disabledProperties();

        for(let c of disabled){

            if(data == c || (c.hasOwnProperty('id') && data == c.id)){

                return true;
            }
        }

        return false;
    }

    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelEditUser();
    }

    /**
     * Displays the password section when checkbox is checked.
     *
     * @param {boolean} checked
     */
    protected displayChangePassword(checked: boolean): void{

        console.log(checked);

        let element = document.querySelector("#form-group-password");

        if(checked){

            element.classList.add('is-open');
        }else{

            element.classList.remove('is-open');
            this.getTemplateViewModel().password('');
            this.getTemplateViewModel().oldPassword('');
            this.getTemplateViewModel().passwordConfirmation('');
        }
    }

    /**
     * Sets up the form.
     *
     * @param vnApp
     * @param {Object[]} allRoles
     * @param {Object[]} allProperties
     * @param {Object[]} userRoles
     * @param {Object[]} userProperties
     */
    public setupForm(vnApp, allRoles: Array<object> = [], allProperties: Array<object> = [], userRoles: Array<object> = [], userProperties: Array<object> = []): void{

        let hasUpdateAccessToRoles      = this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_USER_EDIT_ROLES, vnApp_ads_ninja.ACCESS_TYPE_UPDATE);
        let hasUpdateAccessToRolesAdmin = this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_USER_EDIT_ROLES_ADMIN, vnApp_ads_ninja.ACCESS_TYPE_UPDATE);
        let hasUpdateAccessToBrands     = this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_USER_EDIT_BRANDS, vnApp_ads_ninja.ACCESS_TYPE_UPDATE);
        let canEditUserActive           = this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_USER_EDIT_ACTIVE, vnApp_ads_ninja.ACCESS_TYPE_UPDATE);
        let canUpdateAllProperies       = this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_BRANDS_ALL, vnApp_ads_ninja.ACCESS_TYPE_UPDATE);
        let disabledRoles               = [];
        let disabledProperties          = [];

        if(hasUpdateAccessToRoles && !hasUpdateAccessToRolesAdmin){

            for(let x in allRoles){

                if(allRoles[x].hasOwnProperty('name') && ('admin' === allRoles[x]['name'] || 'administrator' === allRoles[x]['name'])){

                    disabledRoles.push(allRoles[x]);
                }
            }
        }
        else if(!hasUpdateAccessToRoles){

            disabledRoles = allRoles;
            for(let userRole of userRoles){
                if(userRole["id"] == 5){
                    disabledRoles = allRoles.filter(item => item['id'] != 6);
                    break;
                }
            }
        }

        if(!hasUpdateAccessToBrands){

            disabledProperties = allProperties;

            this.getTemplateViewModel().disableSelectButtons(true);
        }else if(!canUpdateAllProperies){

            let idUserProperties = [];
            for(let userProperty of userProperties){

                idUserProperties.push(userProperty["id"]);
            }
            disabledProperties = allProperties.filter(item => idUserProperties.indexOf(item['id']) == -1);
        }

        this.getTemplateViewModel().disabledRoles(disabledRoles);
        this.getTemplateViewModel().disabledProperties(disabledProperties);
        this.getTemplateViewModel().roles(allRoles);
        this.getTemplateViewModel().properties(allProperties);
        this.getTemplateViewModel().disableActive(!canEditUserActive);
        this.getTemplateViewModel().hasUpdateAccessToRolesAdmin(hasUpdateAccessToRolesAdmin);
    }
}
