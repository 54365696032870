import {vnTemplate} from "./vnTemplate";

declare var ko: any;

export class filterDropdown_template extends vnTemplate {

    protected getTemplateHtml(): string {

        return `
                    <select data-bind="
                        options: options,
                        optionsText: 'name',
                        optionsValue: 'id',
                        value: selectedOption,
                        event: { change: onChangeFilterAction },
                        disable: disable
                    "></select>
            `;
    }

    protected getTemplateName(): string {
        return 'filterDropdown-template';
    }

    protected buildViewModel(): object {

        let huit_neuf_this = this;

        return {
            label: ko.observable("")
            ,options: ko.observableArray([{ id: null, name: 'all'}])
            ,selectedOption: ko.observable(0)
            ,setOptions: function (data, event) { huit_neuf_this.setOptions(data); }
            ,setLabel: function (data, event) { huit_neuf_this.setLabel(data); }
            ,setSelectedOption: function (data, event) { huit_neuf_this.setSelectedOption(data); }
            ,onChangeFilterAction: function (data, event) { huit_neuf_this.onChangeFilterAction(data); }
            ,disable: ko.observable(false)
        };
    }

    public onChangeFilterAction(data) {

        this.caller.filterDropdownOnChange(this.idElement);
    }

    public setSelectedOption(optionId: any) {

        this.getTemplateViewModel().selectedOption(optionId);
    }

    public setOptions(options) {

        let temp = this.onChangeFilterAction;
        this.onChangeFilterAction = function(){};

        this.getTemplateViewModel().options(options);

        this.onChangeFilterAction = temp;
    }

    public getOptions(): {id: number, name: string}[]|null {
        return this.getTemplateViewModel().options()
    }

    public getSelectedOption(): {id: number, name: string}|null
    {
        const vm = this.getTemplateViewModel();
        const selectedOption = vm.selectedOption();
        if (!selectedOption) {
            return null;
        }

        const option = vm.options().find((option) => option.id === selectedOption);

        return option? option : null;
    }


    public setLabel(label : string) {

        this.getTemplateViewModel().label(label);
    }

    public setDisable(disable: boolean) {

        this.getTemplateViewModel().disable(disable);
    }
}
